import React, {FunctionComponent, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {useDispatch, useSelector} from 'react-redux';
import {IFilter, ILibraryFiltersResponse} from 'redux/content/interfaces';
import {IStore} from 'redux/interface';
import {setResourceSelectedFilters} from 'redux/content/actions';

import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';

import './styles.scss';
import FilterBar from '../../components/library/filter-bar';
import Categories from './categories';
import Category from './category';
import {FilterModel, FilterValuesModel} from '../../components/library/LibraryModels';
import {LibraryFilter} from '../../components/library/LibraryEnums';
import {resourceContentTypeId} from '../../utils/constants/constants';
import Location from './location';
import Locations from './locations';

interface IResourceFilters {
  filters: ILibraryFiltersResponse | null
  onChange: (filters: IFilter[]) => void
}

export const ResourceFilters: FunctionComponent<IResourceFilters> = ({
  filters,
  onChange
}: IResourceFilters) => {
  const {
    content: {
      resourceSelectedFilters,
      userLocations
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [categoryFilterList, setCategoryFilterList] = useState<FilterModel>();
  const [locationFilterList, setLocationFilterList] = useState<FilterModel>();
  const [needSetUserFilter, setNeedSetUserFilter] = useState(true);

  function dispatchFilters(filterList: IFilter[]) {
    dispatch(setResourceSelectedFilters(filterList));
  }

  useEffect(() => {
    if (needSetUserFilter && userLocations && userLocations.length && locationFilterList && resourceSelectedFilters) {
      const updatedLocations = Object.assign({}, locationFilterList);
      const updatedFilters = resourceSelectedFilters;
      const filterIdx = updatedFilters.findIndex(item => item.serialNumber === updatedLocations.serialNumber);

      userLocations.forEach((location) => {
        updatedLocations.values = updatedLocations?.values.map(sv => {
          if (sv.id === location.id) {
            return {...sv, checked: true};
          }

          return sv;
        });
        updatedFilters[filterIdx].inputValue = [...updatedFilters[filterIdx].inputValue, location.id] as number[];
      });

      setLocationFilterList(updatedLocations);
      dispatchFilters(updatedFilters);
      onChange(updatedFilters);

      setNeedSetUserFilter(false);
    }
  }, [resourceSelectedFilters, userLocations, userLocations]);

  function getFilters(typeFilter: LibraryFilter): FilterModel {
    const filterList = filters?.filters?.find(a => a.name === typeFilter);

    return {
      serialNumber: filterList?.serialNumber,
      values: filterList?.allowValues as FilterValuesModel[]
    };
  }

  function changeCategoryFilter(id: number, checked: boolean) {
    const updatedCategories = Object.assign({}, categoryFilterList);

    updatedCategories.values = updatedCategories?.values.map(sv => {
      if (sv.id === id) {
        return {...sv, checked};
      }

      return sv;
    });
    setCategoryFilterList(updatedCategories);
  }

  function changeLocationFilter(id: number, checked: boolean) {
    const updatedLocations = Object.assign({}, locationFilterList);

    updatedLocations.values = updatedLocations?.values.map(sv => {
      if (sv.id === id) {
        return {...sv, checked};
      }

      return sv;
    });
    setLocationFilterList(updatedLocations);
  }

  useEffect(() => {
    const categories = getFilters(LibraryFilter.Categories);
    const locations = getFilters(LibraryFilter.Locations);

    if (!resourceSelectedFilters.length) {
      setCategoryFilterList(categories);
      setLocationFilterList(locations);

      const filtersList = filters?.filters.filter(f => f.name !== LibraryFilter.ReadingTime)
        .map((filter) => {
          return {
            serialNumber: filter.serialNumber,
            name: filter.name,
            format: filter.format,
            inputValue: filter.name === LibraryFilter.Types ? [resourceContentTypeId] : []
          };
        });

      if (filtersList) {
        onChange(filtersList);
        dispatchFilters(filtersList);
      }
    } else {
      const categoryValues = resourceSelectedFilters.find(a => a.name === LibraryFilter.Categories)?.inputValue as number [];
      const mappedCategoryValues = categories.values?.map((val: FilterValuesModel) => {
        if (categoryValues && categoryValues.find(cv => cv === val.id)) {
          return {...val, checked: true};
        }

        return val;
      });

      categories.values = mappedCategoryValues;
      setCategoryFilterList(categories);

      const locationValues = resourceSelectedFilters.find(a => a.name === LibraryFilter.Locations)?.inputValue as number [];
      const mappedLocationValues = locations.values?.map((val: FilterValuesModel) => {
        if (locationValues && locationValues.find(cv => cv === val.id)) {
          return {...val, checked: true};
        }

        return val;
      });

      locations.values = mappedLocationValues;
      setLocationFilterList(locations);
    }
  }, [filters]);

  const handleToggle = (serialNumber: string | undefined, id: number, checked: boolean, filterType: LibraryFilter) => {
    if (filterType === LibraryFilter.Categories) {
      changeCategoryFilter(id, checked);
    }

    if (filterType === LibraryFilter.Locations) {
      changeLocationFilter(id, checked);
    }

    const updatedFilters = resourceSelectedFilters;
    const filterIdx = updatedFilters.findIndex(item => item.serialNumber === serialNumber);

    if (checked) {
      updatedFilters[filterIdx].inputValue = [...updatedFilters[filterIdx].inputValue, id] as number[];
    } else {
      updatedFilters[filterIdx].inputValue = [...updatedFilters[filterIdx].inputValue].filter(item => item !== id) as number[];
    }
    dispatchFilters(updatedFilters);
    onChange(updatedFilters);
  };

  const handleChangeSearchString = (value: string) => {
    const updatedFilters = resourceSelectedFilters;
    const filterIdx = updatedFilters.findIndex(item => item.name === LibraryFilter.SearchString);

    updatedFilters[filterIdx].inputValue = value;
    dispatchFilters(updatedFilters);
    onChange(updatedFilters);
  };

  const handleClearCategories = () => {
    const updatedFilters = resourceSelectedFilters;
    const filterIdx = updatedFilters.findIndex(item => item.name === LibraryFilter.Categories);

    updatedFilters[filterIdx].inputValue = [];

    dispatchFilters(updatedFilters);
    onChange(updatedFilters);
    const updatedCategories = Object.assign({}, categoryFilterList);

    updatedCategories.values = updatedCategories?.values.map(sv => {
      return {...sv, checked: false};
    });
    setCategoryFilterList(updatedCategories);
  };

  const handleClearLocations = () => {
    const updatedFilters = resourceSelectedFilters;
    const filterIdx = updatedFilters.findIndex(item => item.name === LibraryFilter.Locations);

    updatedFilters[filterIdx].inputValue = [];

    dispatchFilters(updatedFilters);
    onChange(updatedFilters);

    const updatedLocations = Object.assign({}, locationFilterList);

    updatedLocations.values = updatedLocations?.values.map(sv => {
      return {...sv, checked: false};
    });
    setLocationFilterList(updatedLocations);
  };

  return <div className="resource-filters">
    <Categories>
      <>
        <div className="resource-filters__categories">
          {categoryFilterList?.values?.map(v => {
            return (
              <Category
                title={v.name}
                key={v.id}
                serialNumber={categoryFilterList.serialNumber}
                id={v.id}
                checked={v.checked}
                onToggle={handleToggle}/>
            );
          })}
        </div>
        <div className="resource-filters__button">
          <GritxButton
            title={intl.formatMessage({
              id: 'gritx.resource.clear-category',
              defaultMessage: 'Clear categories'
            })}
            variant={ButtonVariant.Outline}
            onClick={handleClearCategories}
          />
        </div>
      </>
    </Categories>
    <Locations>
      <>
        <div className="resource-filters__categories">
          {locationFilterList?.values?.map(v => {
            return (
              <Location
                title={v.name}
                key={`location_${v.id}`}
                serialNumber={locationFilterList.serialNumber}
                id={v.id}
                checked={v.checked}
                onToggle={handleToggle}/>
            );
          })}
        </div>
        <div className="resource-filters__button">
          <GritxButton
            title={intl.formatMessage({
              id: 'gritx.resource.clear-location',
              defaultMessage: 'Clear locations'
            })}
            variant={ButtonVariant.Outline}
            onClick={handleClearLocations}
          />
        </div>
      </>
    </Locations>
    <FilterBar
      selectedFilters={resourceSelectedFilters}
      onToggle={handleToggle}
      onChangeSearchString={handleChangeSearchString}
    />
  </div>;
};
