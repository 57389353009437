import React, {ChangeEvent, FunctionComponent} from 'react';
import './styles.scss';

interface IInputCheckbox {
  value: boolean
  onChange: (value: boolean) => void
  label: string
}

export const InputCheckbox: FunctionComponent<IInputCheckbox> = ({
  value,
  onChange,
  label
}: IInputCheckbox) => {
  function handleChangeCheckbox(e: ChangeEvent<HTMLInputElement>) {
    onChange(e.target.checked);
  }

  return <div className="input-checkbox">
    <input
      id={'input-checkbox'}
      className='input-checkbox__field'
      type='checkbox'
      checked={value}
      onChange={handleChangeCheckbox}
    />
    <label
      className='input-checkbox__label'
      htmlFor={'input-checkbox'}>
      {label}
    </label>
  </div>;
};
